/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Core's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.pointer {
    cursor: pointer;
}

.secondary-text {
    color: #94a3b8 !important;
}

.breadcrumb-unit-name {
    color: #4f46e5;
    font-size: 14px !important;
}

.multi-line-option {
    .mat-option-text {
        padding: 0;
    }

    min-height: 48px !important;
    line-height: normal !important;
    height: auto !important;
    padding: 10px 20px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
}

.bullhorn-blue {
    background-color: #0064a8;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
    z-index: 99999 !important;
}
